export const purple = '#4263F7'
export const purpleDark = '#2810D8'
export const ocean = '#39CCCC'
export const orange = 'indianred'

export const main = '#272833'
export const primary = '#FFC600'
export const white = '#FFFFFF'
export const grayExtraLight = '#EFF2F7'
export const grayLight = '#DDDDDD'
export const gray = '#AAAAAA'
export const grayDark = '#8492A6'

export const text = main
export const link = '#0074D9'
export const background = white
export const border = gray

export const grayUltraLight = '#FCFBFA'
export const grayExtraDark = '#1D2330'
export const dark = '#13161F'

export const blueLight = '#e9f2fa'
export const blue = '#0074D9'
export const skyBlue = '#7FDBFF'
export const negative = '#FF4136'
