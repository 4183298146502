import * as colors from './colors'

export const light = {
  ...colors,
  primary: colors.blue,
  text: colors.grayDark,
  link: colors.blue,
  footerText: colors.grayDark,
  sidebarBg: colors.grayExtraLight,
  sidebarText: colors.dark,
  sidebarHighlight: null,
  sidebarBorder: colors.grayLight,
  background: colors.white,
  border: colors.grayLight,
  theadColor: colors.gray,
  theadBg: colors.grayExtraLight,
  tableColor: colors.dark,
  tooltipBg: colors.dark,
  tooltipColor: colors.grayExtraLight,
  codeBg: colors.grayExtraLight,
  codeColor: colors.gray,
  preBg: colors.grayExtraLight,
  blockquoteBg: colors.grayExtraLight,
  blockquoteBorder: colors.grayLight,
  blockquoteColor: colors.gray,
  propsText: colors.gray,
  propsBg: colors.grayUltraLight,
}

export const dark = {
  ...colors,
  primary: colors.skyBlue,
  text: colors.grayExtraLight,
  link: colors.skyBlue,
  footerText: colors.grayLight,
  sidebarBg: colors.dark,
  sidebarText: colors.grayLight,
  sidebarHighlight: null,
  sidebarBorder: colors.grayDark,
  background: colors.grayExtraDark,
  border: colors.grayDark,
  theadColor: colors.gray,
  theadBg: colors.grayDark,
  tableColor: colors.grayExtraLight,
  tooltipBg: colors.dark,
  tooltipColor: colors.grayExtraLight,
  codeBg: colors.gray,
  codeColor: colors.grayExtraLight,
  preBg: colors.grayDark,
  blockquoteBg: colors.grayDark,
  blockquoteBorder: colors.gray,
  blockquoteColor: colors.gray,
  propsText: colors.grayLight,
  propsBg: colors.dark,
}
