import * as colors from './styles/colors'
import { styles } from './styles'

export const config = {
  colors,
  styles,
  radii: '2px',
  mode: 'light',
  showPlaygroundEditor: false,
  linesToScrollEditor: 18,
}
