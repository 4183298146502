import * as React from 'react'
import styled from 'styled-components'

import { Logo as BaseLogo } from '@components/ui'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 80px;
  background-image: linear-gradient(to left,#FFF 50%,#FFCD00 100%);
  margin-top: 5px;
`

const LinkTo = styled.a`
  margin: 0 5px;
`

const Logo = styled(BaseLogo)`
  margin: 0 10px;
`

export const Footer = () => (
  <Wrapper>
    <div>
      <span>We’d love to hear from you!</span> <LinkTo href={'mailto:support@invisr.com'} target={'_blank'}>Contact us</LinkTo> <span>with questions and suggestions!</span>
    </div>
    <div style={{ marginLeft: 'auto' }}>
      <LinkTo href={'https://runpolystack.com/'} target={'_blank'}><Logo height={30} /></LinkTo>
    </div>
  </Wrapper>
)
