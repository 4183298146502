import * as React from 'react'
import { theme, ComponentsProvider, useConfig } from 'docz'
import { ThemeProvider } from './theme-provider'
import webfont from 'webfontloader'
import { get } from '@utils'

import * as modes from './styles/modes'
import { config } from './config'
import { Global } from '@styles/global'
import { Main } from '@components/shared'
import * as components from '@components/ui'
import { components as defaultComponents } from 'docz-theme-default'

webfont.load({
  google: {
    families: ['Inconsolata', 'Zilla Slab:300,400,600'],
  },
})

const map = {
  ...defaultComponents,
  page: components.Page,
  loading: components.Loading,
  h1: components.H1,
  h2: components.H2,
  h3: components.H3,
  h4: components.H4,
  h5: components.H5,
  h6: components.H6,
  hr: components.Hr,
  img: components.Image,
  ul: components.List,
  pre: components.Pre,
  blockquote: components.Blockquote,
}

const Theme: React.SFC = ({ children }) => {
  const config = useConfig()
  return (
    <ThemeProvider>
      <ComponentsProvider components={map}>
        <Main>
          <Global />
          {children}
        </Main>
      </ComponentsProvider>
    </ThemeProvider>
  )
}

export default theme(config, ({ mode, codemirrorTheme, ...config }) => ({
    ...config,
    mode,
    codemirrorTheme: codemirrorTheme || `docz-${mode}`,
    colors: {
      ...get(modes, mode),
      ...config.colors,
    },
  }))(Theme)
