import styled from 'styled-components'
import { get } from '@utils'

export const Code = styled.code`
  margin: 0 3px;
  padding: 3px 5px;
  border-radius: 3px;
  background: ${get('colors.grayExtraLight')};
  font-size: 16px;
  color: ${get('colors.orange')};
`
