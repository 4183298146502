import * as React from 'react'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Fragment, SFC } from 'react'
import { Entry, Link as BaseLink, useDocs, useMenus } from 'docz'
import { useWindowSize } from 'react-use'
import styled, { css } from 'styled-components'
import { get } from '@utils'
import { mq } from '@styles/responsive'

import { breakpoints } from '@styles/responsive'
import { mainContext } from '../Main'
import { TOPBAR_LINKS, IconLink } from '../Topbar'

const DEFAULT_COLLAPSED_LINKS = ['Component Reference', 'Integration Datasources', 'Utility Datasources']

interface WrapperProps {
  opened: boolean
  mobile: boolean
}

const toggle = (p: WrapperProps) => {
  return !p.opened && p.mobile ? '-100%' : '0'
}

const SidebarWrapper = styled.div<WrapperProps>`
  width: 280px;
  min-width: 280px;
  padding: 40px 20px 40px 0;
  overflow: auto;
  border-right: 1px solid ${get('colors.grayLight')};
  background: #fff;
  transition: transform 0.2s, background 0.3s;
  transform: translateX(${toggle});

  ${p =>
    p.mobile &&
    css`
position: absolute;
top: 0;
left: 0;
overflow: auto;
z-index: 9999;
padding: 30px;
`};
`

const Wrapper = styled.div`
  position: -webkit-sticky;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mq({
  position: ['relative', 'relative', 'sticky', 'sticky'],
  top: ['0px', '0px', '50px', '50px'],
  })};
`

const Link = styled(BaseLink)`
  font-size: 16px;
  padding: 2px 0 2px 10px;

  &,
  &:visited {
    color: ${get('colors.grayDark')};
  }

  &.active,
  &:hover {
    opacity: 0.8;
    color: #272833;
    font-weight: 700;
  }
`

const SmallLink = styled(BaseLink)`
  font-size: 14px;
  padding: 0 0 5px 20px;

  &,
  &.active,
  &:visited {
    color: ${get('colors.gray')};
  }

  &:hover {
    color: ${get('colors.purple')};
  }
`

const Submenu = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
`

const MenuGroup = styled.h2`
  margin: 30px 0 5px;
  font-size: 14px;
  opacity: 0.3;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  &:first-child {
  margin-top: 0;
  }
  &.active {
    font-weight: 700;
    opacity: .8;
  }
  &.active:before {
    position: absolute;
    content: '';
    top: 100%;
    left: 0;
    width: 20%;
    height: 3px;
    background: #FFC600;
  }
`

interface OpenProps {
  opened: boolean
}

const ToggleBackground = styled.div`
  content: '';
  display: ${(p: OpenProps) => (!p.opened ? 'none' : 'block')};
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  z-index: 98;
`

interface MenuProps {
  doc: Entry
  active: boolean
  onClick: React.MouseEventHandler
}

const Menu: SFC<MenuProps> = ({ doc, active, onClick }) => {
  const headings = doc.headings.filter(
    heading => heading.depth > 1 && heading.depth < 3
  )

  return (
    <Fragment>
      <Link to={doc.route} onClick={onClick}>
        {doc.name}
      </Link>
      {/*{active && headings.length > 0 && (*/}
      {/*  <Submenu>*/}
      {/*    {headings.map(heading => (*/}
      {/*      <SmallLink*/}
      {/*        key={heading.slug}*/}
      {/*        to={`${doc.route}#${heading.slug}`}*/}
      {/*        onClick={onClick}*/}
      {/*      >*/}
      {/*        {heading.value}*/}
      {/*      </SmallLink>*/}
      {/*    ))}*/}
      {/*  </Submenu>*/}
      {/*)}*/}
    </Fragment>
  )
}

interface TopbarMenuProps {
  onClick: React.MouseEventHandler
}

const TopbarMenu: React.SFC<TopbarMenuProps> = ({ onClick }) => {
  return (
    <React.Fragment>
      {TOPBAR_LINKS.map(({ id, children, ...props }) => {
        const Component = props.to ? Link : IconLink
        return (
          <Component key={id} {...props} onClick={onClick}>
            {children}
          </Component>
        )
      })}
    </React.Fragment>
  )
}

interface SidebarProps {
  menu: string
  pathname?: string
  mobile?: boolean
}

export const Sidebar: SFC<SidebarProps> = ({
  menu: current,
  pathname,
  mobile,
}) => {
  const sidebarRef = useRef<any>(null)
  const docs = useDocs()
  const menus = useMenus()
  // const currentMenu = menus && menus.find(item => item.menu && item.menu.find(item => pathname?.includes(item.route)))
  // const [activeSection, setActiveSection] = useState('')
  const [expandedSideLinks, setExpandedSideLinks] = useState(getExpandedSections(menus))
  const { width } = useWindowSize()
  const { showing, setShowing } = useContext(mainContext)

  const isDesktop = width > breakpoints.tablet
  const toggle = useCallback(() => {
    setShowing((s: any) => !s)
  }, [])

  const handleSidebarToggle = (ev: React.SyntheticEvent<any>) => {
    if (isDesktop) return
    toggle && toggle()
  }

  const setActiveSectionExpand = (isActive: boolean, id: string) => {
    // setActiveSection(isActive ? '' : id)
    const { [id]: currentLink, ...restSideLinks } = expandedSideLinks

    setExpandedSideLinks({
      [id]: !currentLink,
      ...restSideLinks
    })
  }

  useEffect(() => {
    if (sidebarRef && sidebarRef.current) {
      const active = sidebarRef.current.querySelector('a.active')
      active && active.scrollIntoView({ block: 'center', behavior: 'auto' })
    }
  }, [pathname])

  return (
    <React.Fragment>
      <SidebarWrapper ref={sidebarRef} opened={showing} mobile={Boolean(mobile)}>
        <Wrapper>
          {mobile && <TopbarMenu onClick={handleSidebarToggle} />}
          {menus &&
            menus.map(({ id, name, menu }) => {
              if (!menu) return null
              const hasActiveChild = menu.some(item => pathname?.includes(item.route))
              const isActive = !DEFAULT_COLLAPSED_LINKS.includes(name)
              return (
                <React.Fragment key={id}>
                  <MenuGroup
                    className={hasActiveChild ? 'active' : ''}
                    onClick={() => setActiveSectionExpand(isActive, id)}
                  >
                    {name}
                  </MenuGroup>
                  {
                     menu.length > 0 && (expandedSideLinks[id] || hasActiveChild) &&
                    <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
                      {menu.map(item => {
                        const doc = docs && docs.find(doc => doc.name === item.name)
                        if (!doc) return null
                        return (
                          <Menu
                            key={doc.id}
                            doc={doc}
                            active={Boolean(
                              pathname && pathname.includes(doc.route)
                            )}
                            onClick={handleSidebarToggle}
                          />
                        )
                      })}
                    </div>
                  }
                </React.Fragment>
              )
            })}
        </Wrapper>
      </SidebarWrapper>
      {!isDesktop && (
        <ToggleBackground opened={showing} onClick={handleSidebarToggle} />
      )}
    </React.Fragment>
  )
}

const getExpandedSections = (menus: any) => {
  return menus.reduce((acc: any, menu: any) => {
    if (!acc) acc = {}
    acc[menu.id] = !DEFAULT_COLLAPSED_LINKS.includes(menu.name)
    return acc
  }, {})
}
