import styled from 'styled-components'
import { get } from '@utils'

export const H3 = styled.h3`
  ${get('styles.h3')};

  code {
    font-size: 22px;
    color: ${get('colors.grayDark')};
    padding: 5px 10px;
  }
`
