import * as React from 'react'
import { useContext, useState } from 'react'
import { useWindowSize } from 'react-use'
import styled, { css } from 'styled-components'
import { Link, useDocs } from 'docz'

import { Hamburguer } from '@components/shared/Sidebar/Hamburguer'
import { Container, Logo } from '@components/ui'
import { mq, breakpoints } from '@styles/responsive'
import { mainContext } from '../Main'
import { get } from '@utils'
import * as JsSearch from 'js-search'
import '@blueprintjs/core/lib/css/blueprint.css'

const Wrapper = styled.div`
  z-index: 999;
  width: 100%;
  position: fixed;
  height: 60px;
  background-image: linear-gradient(to left, #FFF 50%, #FFCD00 100%);

  ${Container} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    ${mq({
      padding: ['0 14px', '0 20px', '0 20px', '0 20px'],
    })};
  }
`

const LogoLink = styled(Link)`
  height: 30px;
`

const Menu = styled.div`
  display: flex;
`

const linkStyle = (p: any) => css`
  color: ${get('colors.main')(p)};
  opacity: 0.5;
  transition: opacity 0.2s;
  font-size: 15px;
  font-weight: 400;

  &.active,
  &:visited,
  &:hover {
    color: ${get('colors.main')(p)};
    opacity: 1;
  }
`

const MenuLink = styled(Link)`
  ${linkStyle};
  margin: 0 10px;
`

export const IconLink = styled.a`
  ${linkStyle};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 20px;

  svg {
    stroke: ${get('colors.main')};
  }
`

const SearchWrapper = styled.div`
  min-width: 300px;
  position: relative;

  & small {
    color: #5c7080;
    font-size: .7em;
  }
  & .bp3-input {
    padding-right: 10px !important;
  }
  & .bp3-input:focus,
  & .bp3-input.bp3-active {
    box-shadow: 0 0 0 1px #ffce0f, 0 0 0 3px rgb(255 206 15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  }
`

const SearchResultsWrapper = styled.div`
  position: absolute;
  top: 40px;
  min-height: 200px;
  max-height: 400px;
  min-width: 300px;
  background-color: #fff;
  border-radius: .25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.7);
  overflow: scroll;
`

const SearchResult = styled.div`
  padding: .5rem;

  &:hover {
    background-color: #ffcd05;
  }

  & a,
  & a:hover,
  & a:visited,
  & a:focus {
    text-decoration: none;
    color: #272833;
  }

  & .result-key {
    text-transform: capitalize;
  }
`

interface MenuListItem {
  id: number
  children: React.ReactNode
  [key: string]: any
}

export const TOPBAR_LINKS: MenuListItem[] = [
  // {
  //   id: 1,
  //   children: 'Home',
  //   to: '/',
  // },
  // {
  //   id: 2,
  //   children: 'Documentation',
  //   to: '/docs/getting-started',
  // },
  // {
  //   id: 3,
  //   children: 'Blueprints',
  //   to: '/blueprints/family-office',
  // },
  // {
  //   id: 4,
  //   children: 'Components',
  //   to: '/reference/Accordion',
  // },
  // {
  //   id: 4,
  //   children: 'Themes',
  //   to: '/themes',
  // },
]

export const Topbar = () => {
  const { width } = useWindowSize()
  const showFullMenu = width > breakpoints.tablet
  const { showing, setShowing } = useContext(mainContext)
  const docs = useDocs()
  const docsSearch: any = []
  !docsSearch.length && docs?.forEach(doc => {
    if (doc.menu) {
      const descriptions = doc.headings.filter(heading => heading.depth === 3 || heading.depth === 4) || []
      const description = descriptions.reduce((acc, desc) => {
        if (!acc) acc = ''
        if (desc.value && desc.value.length) {
          acc  += ` ${desc.value}`
        }
        return acc
      }, '')
      docsSearch.push({
        id: doc.id,
        name: doc.name,
        section: doc.menu,
        route: doc.route,
        desc: description
      })
    }
  })
  return (
    <Wrapper>
      <Container>
        <LogoLink to="/">
          <Logo height={30} small={!showFullMenu} />
        </LogoLink>
        <Menu>
          {
            showFullMenu &&
            <>
              {
                TOPBAR_LINKS.map(({ id, children, ...props }) => {
                  const Component = props.to ? MenuLink : IconLink
                  return (
                    <Component key={id} {...props}>
                      {children}
                    </Component>
                  )
                })
              }
              <Search docsSearch={docsSearch} />
            </>
          }
          {!showFullMenu && (
            <Hamburguer
              opened={showing}
              onClick={() => setShowing((s: any) => !s)}
            />
          )}
        </Menu>
      </Container>
    </Wrapper>
  )
}

export const Search = (props: any) => {
  const [searchResults, setSearchResults] = useState([])
  const search = new JsSearch.Search('id')
  search.addIndex('desc')
  search.addIndex('name')
  search.addDocuments(props.docsSearch)

  const searchFn = (e: any) => {
    const { value } = e.target
    if (value && value.length >= 3) {
      const results = search.search(value)
      setSearchResults(results)
      return
    }
    setSearchResults([])
  }

  return (
    <>
      <SearchWrapper className="bp3-input-group">
        <span className="bp3-icon bp3-icon-search" />
        <input
          className="bp3-input"
          type="search"
          placeholder="Search the docs"
          dir="auto"
          onChange={searchFn}
        />
        {
          searchResults && searchResults.length > 0 &&
          <SearchResultsWrapper>
            {
              searchResults.map((result: any, index: number) => (
                <SearchResult key={`result-${index}`}>
                  <LogoLink to={result?.route}>
                    <div className={'result-key'}>
                      {result?.name || ''}
                    </div>
                    <div>
                      <small>
                        <span className={'mr-1'}>{result?.section || ''}</span>
                        <span className={'mr-1'}> {'>'} </span>
                        <span className={'mr-1 result-key'}>{result?.name || ''}</span>
                      </small>
                    </div>
                  </LogoLink>
                </SearchResult>
              ))
            }
          </SearchResultsWrapper>
        }
      </SearchWrapper>
    </>
  )
}
