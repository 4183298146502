import styled from 'styled-components'
import { get } from '@utils'

export const H1 = styled.h1`
  position: relative;
  display: table;
  ${get('styles.h1')};

  &:before {
    position: absolute;
    content: '';
    bottom: 5%;
    left: 0;
    width: 30%;
    height: 3px;
    background: ${get('colors.primary')};
  }
`
