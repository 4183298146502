import styled from 'styled-components'
import { get } from '@utils'

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid ${get('colors.gray')};
  border-radius: 5px;
`
